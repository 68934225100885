import React from "react";
import Spinner from "theme/components/atoms/Spinner";
import loadable from "@loadable/component";

const defaultWidgetsMap = {
  "tier3_extendedcontent/customcontent_widget_view": loadable(
    () => import("./ExtendedContent.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "Kaporal\\Catalog\\Block\\Widget\\Eshop": loadable(
    () => import("./WidgetEshop/WidgetEshop.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_products": loadable(
    () => import("./WidgetProductSlider/ProductSlider.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kpl_cms/widget_header": loadable(
    () => import("./WidgetHeader/WidgetHeader.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporal_widgets/widget_scratch": loadable(
    () => import("./WidgetScratch/WidgetScratch.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_merchandising": loadable(
    () => import("./WidgetMerchandising/WidgetMerchandising.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporal_widgets/widget_fcbanner": loadable(
    () => import("./WidgetFcbanner/WidgetFcbanner.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_card": loadable(
    () => import("./WidgetCard/WidgetCard.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_slider_merchandising": loadable(
    () => import("./WidgetMerchandisingSlider/WidgetMerchandisingSlider.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_fiveproducts": loadable(
    () => import("./Widget5Products/Widget5Products.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_largeillustration": loadable(
    () => import("./WidgetLargeIllustration/WidgetLargeIllustration.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_slidertwoproducts": loadable(
    () => import("./WidgetSliderTwoProducts/WidgetSliderTwoProducts.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "Magento\\Cms\\Block\\Widget\\Block": loadable(
    () => import("./WidgetBlock/WidgetBlock"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_blog/post_widget_page": loadable(
    () => import("theme/modules/Posts/CategoryPost/PostsList"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widgets_illustration": loadable(
    () => import("./WidgetIllustration/WidgetIllustration.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widgets_lookbook": loadable(
    () => import("./WidgetLookbook/WidgetLookbook.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/slider_images_illustration": loadable(
    () => import("./WidgetSliderImagesIllustration"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporal_widgets/widget_birthdaygamewinner": loadable(
    () => import("./WidgetBirthdayGameWinner"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporal_blog/post_widget_latest": loadable(
    () => import("./WidgetBlogLatest/WidgetBlogLatest.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporal_blog/category_posts_widget": loadable(
    () => import("./WidgetCategoryPosts/WidgetCategoryPosts.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widgets_double_illustration": loadable(
    () => import("./WidgetDoubleIllustration/WidgetDoubleIllustration.js"),
    {
      fallback: <Spinner />,
    }
  ),
  "kaporalmrs_widgets/widget_topbanner": loadable(
    () => import("./WidgetTopBanner/WidgetTopBanner.js"),
    {
      fallback: <Spinner />,
    }
  ),
};
const RenderNothing = (props) => {
  if (process.env.NODE_ENV === "development") {
    console.warn(
      `The widget type ${JSON.stringify(
        props.type
      )} is not supported in MagentoWysiwyg. Please check "theme/modules/WysiwygV2/MagentoWysiwyg/Widget/getWidgetComponent.js" to see what's going on.`
    );
  }
  return null;
};

const getWidgetComponent = (customWidgetsMap) => {
  const widgetsMap = {
    ...defaultWidgetsMap,
    ...(customWidgetsMap || {}),
  };
  return (type) => {
    return widgetsMap[type] || RenderNothing;
  };
};

export default getWidgetComponent;
