import React from "react";
import Link from "theme/components/atoms/Typography/Link";

const LinkComponent = ({ href, className, ...rest }) => {
  const modifiers =
    className &&
    className.split(" ").reduce((acc, cur) => ({ ...acc, [cur]: true }), {});

  const isExternal = !href || /^(https?:)?\/\//.test(href);
  const isSpecialLink = !isExternal && /^.+:/.test(href);
  if (!href) {
    href = "/";
  }
  const to = href.replace(/^\/[a-z]{2}_[a-z]{2}/, "");

  if (!isSpecialLink) {
    return (
      <Link to={to} modifiers={modifiers} external={isExternal} {...rest} />
    );
  } else {
    return (
      <a href={to} className={className} {...rest}>
        {rest.children}
      </a>
    );
  }
};

export default LinkComponent;
